import { useQuery } from 'react-query'
import { message } from 'antd'
import { getUsers } from 'utils/apis/users'

export function useUsers(params = {}, options = {}) {
  return useQuery(
    ['users', params],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getUsers(params)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}
