import { useMutation, useQuery } from 'react-query'

import {
  createOutlet,
  createProductFavorites,
  deleteOutlet,
  deleteProductFavorites,
  editOutlet,
  getOutlets,
  getPosUsers,
  getProductFavorites,
  moveProductFavorite,
} from 'utils/apis'

export const useProductFavorites = ({ options }) => {
  return useQuery(
    ['products-favorites'],
    async () => {
      const {
        data: { data },
      } = await getProductFavorites()
      return data
    },
    { ...options },
  )
}

export const useCreateProductFavorites = () => {
  return useMutation((payload) => createProductFavorites(payload))
}

export const useDeleteProductFavorites = () => {
  return useMutation(({ id }) => deleteProductFavorites({ id }))
}

export const useMoveProductFavorite = () => {
  return useMutation(({ id, payload }) => moveProductFavorite({ id, payload }))
}

export const useOutlets = ({ options, payload }) => {
  return useQuery(
    ['outlets', payload],
    async () => {
      const {
        data: { data },
      } = await getOutlets({ payload })
      return data
    },
    { ...options },
  )
}

export const useCreateOutlet = () => {
  return useMutation(({ payload }) => createOutlet({ payload }))
}

export const useEditOutlet = () => {
  return useMutation(({ id, payload }) => editOutlet({ id, payload }))
}

export const useDeleteOutlet = () => {
  return useMutation(({ id, payload }) => deleteOutlet({ id, payload }))
}

export const usePosUsers = ({ options, payload }) => {
  return useQuery(
    ['users', payload],
    async () => {
      const {
        data: { data },
      } = await getPosUsers({ payload })
      return data
    },
    { ...options },
  )
}
