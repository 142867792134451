import React from 'react'
import { injectIntl } from 'react-intl'
import { Input, Form } from 'antd'

class FinanceTagForm extends React.Component {
  nameRef = React.createRef()

  componentDidMount() {
    const { innerRef } = this.props
    if (innerRef.current) {
      innerRef.current.resetFields()
    }
    if (this.nameRef.current) {
      setTimeout(() => this.nameRef.current.focus(), 200)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      if (this.props.show) {
        const form = this.props.innerRef.current
        if (form) {
          form.resetFields()
        }
      }
    }
  }

  render() {
    const { tag, intl, innerRef } = this.props

    return (
      <Form
        id="tagForm"
        ref={innerRef}
        layout="vertical"
        initialValues={{
          name: tag ? tag.name : null,
        }}
        onFinish={this.props.submitHandler}
      >
        <Form.Item
          label={intl.formatMessage({ id: 'financeTag.name' })}
          name="name"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'financeTag.please_input_the_tag_name' }),
            },
            {
              max: 50,
              message: intl.formatMessage({ id: 'financeTag.max_characters' }, { value: 50 }),
            },
            {
              min: 2,
              message: intl.formatMessage({ id: 'financeTag.min_characters' }, { value: 2 }),
            },
            {
              pattern: /^[\w\s.]+$/,
              message: intl.formatMessage({ id: 'financeTag.only_alphanumeric_allowed' }),
            },
          ]}
        >
          <Input
            ref={this.nameRef}
            placeholder={intl.formatMessage({ id: 'financeTag.name' })}
            autoFocus
          />
        </Form.Item>
      </Form>
    )
  }
}

const Component = injectIntl(FinanceTagForm)

export default React.forwardRef((props, ref) => <Component innerRef={ref} {...props} />)
