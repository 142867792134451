import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Alert } from 'antd';
import { useSelector } from 'react-redux';
import { billingSelector, optionsSelector } from 'redux/selectors';

function getTrialMessage(remainingDays) {
  const hoursUntilMidnight = moment().endOf('day').diff(moment(), 'hours');
  const upgradeLink = (
    <Link to="/settings/billing/upgrade">
      <strong>
        <FormattedMessage id="billing.upgrade_now" />
      </strong>
    </Link>
  );
  const commonValues = {
    setup: upgradeLink,
  };

  if (remainingDays === 0) {
    return (
      <FormattedMessage
        id="setup.trial_zero"
        values={{ setup: upgradeLink }}
      />
    );
  }

  if (remainingDays === 1) {
    return (
      <FormattedMessage
        id="setup.trial_one_day"
        values={{ ...commonValues, hour: hoursUntilMidnight }}
      />
    );
  }

  return (
    <FormattedMessage
      id="setup.trial"
      values={{ ...commonValues, days: remainingDays }}
    />
  );
}


function TrialWarning({ showIcon = true, style, ...props }) {
  const { plan, expiry_date } = useSelector(billingSelector);
  const { demo } = useSelector(optionsSelector);
  const remainingDays = moment(expiry_date).diff(moment(), 'days');

  if (!plan || !expiry_date || (plan && !plan.includes('Trial') || demo === 1) || remainingDays < 0) {
    return null;
  }

  const alertMessage = getTrialMessage(remainingDays);

  return (
    <Alert
      className="notification-demo hide-on-print"
      style={{ backgroundColor: '#ffefb3', ...style }}
      message={alertMessage}
      type="warning"
      showIcon={showIcon}
      {...props}
    />
  )
}

export default TrialWarning
