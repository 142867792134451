import React from 'react'
import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { accountTransReportSelector } from 'redux/selectors'
import { hideAccountTransactionReport } from 'redux/finance/actions'
import { Cancel } from 'components/UI'
import AccountTransactions from './index'

const AccountTransactionModal = () => {
  const dispatch = useDispatch()

  const accountTrans = useSelector(accountTransReportSelector)

  const hideHandler = () => dispatch(hideAccountTransactionReport())
  return (
    <Modal
      // title={translation.menu && `${intl.formatMessage({ id: 'button.add' })} ${translation.menu.tags}`}
      visible={accountTrans.show}
      onCancel={hideHandler}
      footer={[<Cancel key={`_${0}`} onClick={hideHandler} />]}
      maskTransitionName=""
      width="90%"
      bodyStyle={{ paddingTop: 40 }}
    >
      <AccountTransactions params={accountTrans.params} isOnModal />
    </Modal>
  )
}

export default AccountTransactionModal
