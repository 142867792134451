/* eslint-disable no-lonely-if */
import React, {useState} from 'react'
import {Button, Dropdown, Menu, Modal} from 'antd'
import {
  CompressOutlined,
  DownOutlined,
  ExpandOutlined,
  FileExcelOutlined,
  MailOutlined,
  PrinterOutlined,
  SettingOutlined,
  ShareAltOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons'
import {injectIntl} from 'react-intl'
import ReactToPrint from 'react-to-print'
import axios from 'axios'

import {Cancel, SendWaModal} from 'components/UI'
import SendEmailModal from 'containers/EmailTemplate/SendEmailModal'

// Print and export xls/csv Dropdown
const PrintDropdown = ({
  intl,
  exportUrl,
  content,
  removeAfterPrint,
  isPrint = true,
  noPrintAction = () => {},
  goToLayout,
  withLandscape,
  getEmailTemplate,
  sendEmail,
  getWaTemplate,
  sendWaTitle,
  sendTitle,
  withoutPrint,
  withPrintType,
  printTypeModalTitle,
  totalData,
  onPrintExpanded,
  printProps,
  printExpandedProps,
  printCompactProps,
  printPortraitProps,
  printLandscapeProps,
  xlsProps,
  csvProps,
  dropdownMenuPrintProps,
}) => {
  const [sendEmailModal, setSendEmailModal] = React.useState(false)
  const [emailSent, setEmailSent] = React.useState(false)
  const [sendWaModal, setSendWaModal] = React.useState(false)
  const [showPrintModal, setShowPrintModal] = useState(false)

  const onCloseEmailModal = () => {
    setEmailSent(false)
    setSendEmailModal(false)
  }

  const exportTo = (exportType) => {
    axios.get(`${exportUrl}&export=${exportType}`, { responseType: 'blob' }).then((r) => {
      const fileName = r.request.getResponseHeader('Content-Disposition').split('filename=')[1]
      const blobURL = URL.createObjectURL(r.data)
      const tempLink = document.createElement('a')
      tempLink.href = blobURL
      tempLink.setAttribute('download', fileName)
      tempLink.click()
    })
  }

  const renderPrintRow = () => {
    const rows = []

    let length = totalData
    for (let i = 0; length > 0; i += 1) {
      length -= 1000

      const lastRow = length <= 0 ? totalData : (i + 1) * 1000
      const rowCount = `${i * 1000 + 1} - ${lastRow}`
      rows.push(
        <tr key={length} className="ant-table-row">
          <td>
            {intl.formatMessage({ id: 'financeProduct.row' })} {rowCount}
          </td>
          <td>
            <a
              href="#"
              className="text-primary"
              onClick={(event) => {
                event.preventDefault()

                if (typeof onPrintExpanded === 'function') {
                  /**
                   * custom pint expanded, this use if BE not supported change print_type
                   * directly on print url
                   */
                  onPrintExpanded({
                    event,
                    per_page: lastRow,
                    page: i * 1000 + 1,
                  })
                }
              }}
            >
              Print
            </a>
          </td>
        </tr>,
      )
    }

    return rows
  }

  const items = []
  if (getEmailTemplate && sendEmail) {
    items.push({
      key: '5',
      icon: <MailOutlined />,
      onClick: () => {
        setSendEmailModal(true)
      },
      label:
        sendTitle === 'button.send_email' || !sendTitle
          ? intl.formatMessage({ id: 'button.send_email' })
          : sendTitle,
    })
  }
  if (getWaTemplate) {
    items.push({
      key: '8',
      icon: <WhatsAppOutlined />,
      onClick: () => {
        setSendWaModal(true)
      },
      label:
        sendWaTitle === 'button.send_wa' || !sendWaTitle
          ? intl.formatMessage({ id: 'button.send_wa' })
          : sendWaTitle,
    })
  }

  const shareMenu = <Menu items={items} />
  const menuItems = []
  if (isPrint) {
    menuItems.push({
      key: '1',
      icon: <PrinterOutlined />,
      label: (
        <ReactToPrint
          content={content}
          removeAfterPrint={removeAfterPrint}
          trigger={() => <div>Print</div>}
        />
      ),
    })
  } else {
    if (withLandscape) {
      menuItems.push(
        {
          key: 'potrait',
          icon: <PrinterOutlined />,
          label: 'Print Potrait',
          onClick: () => noPrintAction({ landscape: 0 }),
          ...printPortraitProps,
        },
        {
          key: 'landscape',
          icon: <PrinterOutlined />,
          label: 'Print Landscape',
          onClick: () => noPrintAction({ landscape: 1 }),
          ...printLandscapeProps,
        },
      )
    } else {
      menuItems.push({
        key: '0',
        icon: <PrinterOutlined />,
        label: 'Print',
        onClick: withPrintType ? undefined : noPrintAction,
        ...printProps,
        children: withPrintType
          ? [
              {
                key: '1',
                icon: <ExpandOutlined />,
                label: 'Expanded',
                onClick: () => setShowPrintModal(true),
                ...printExpandedProps,
              },
              {
                key: '2',
                icon: <CompressOutlined />,
                label: 'Compact',
                onClick: () => noPrintAction({ printType: 'compact' }),
                ...printCompactProps,
              },
            ]
          : undefined,
      })
    }
  }

  if (exportUrl) {
    menuItems.push(
      {
        key: '2',
        icon: <FileExcelOutlined />,
        label: 'XLS',
        onClick: () => exportTo('xls'),
        ...xlsProps,
      },
      {
        key: '3',
        icon: <FileExcelOutlined />,
        label: 'CSV',
        onClick: () => exportTo('csv'),
        ...csvProps,
      },
    )
  }
  if (goToLayout) {
    menuItems.push({
      key: '4',
      icon: <SettingOutlined />,
      label: intl.formatMessage({ id: 'button.change_layout' }),
      onClick: goToLayout,
    })
  }
  const menu = <Menu {...dropdownMenuPrintProps} items={menuItems} />

  if (!isPrint) {
    return (
      <>
        {((getEmailTemplate && sendEmail) || getWaTemplate) && (
          <Dropdown overlay={shareMenu} trigger={['click']}>
            <Button style={{ marginRight: 4 }} id="btn-share">
              <ShareAltOutlined /> {intl.formatMessage({ id: 'button.share' })} <DownOutlined />
            </Button>
          </Dropdown>
        )}
        {!withoutPrint && (
          <Dropdown.Button
            overlay={menu}
            trigger={['click']}
            icon={<DownOutlined />}
            onClick={() => noPrintAction()}
          >
            <PrinterOutlined /> Print
          </Dropdown.Button>
        )}
        <SendWaModal
          intl={intl}
          sendTitle={sendWaTitle}
          getWaTemplate={getWaTemplate}
          show={sendWaModal}
          onCloseWaModal={() => setSendWaModal(false)}
        />
        <SendEmailModal
          intl={intl}
          sendTitle={sendTitle}
          getEmailTemplate={getEmailTemplate}
          sendEmail={sendEmail}
          show={sendEmailModal}
          emailSent={emailSent}
          setEmailSent={setEmailSent}
          onCloseEmailModal={onCloseEmailModal}
          onPrintPdf={() => (withLandscape ? noPrintAction({ landscape: 0 }) : noPrintAction())}
        />
        {withPrintType ? (
          <Modal
            title={`${intl.formatMessage({ id: 'button.print' })} ${printTypeModalTitle}`}
            visible={showPrintModal}
            onCancel={() => setShowPrintModal(false)}
            footer={[<Cancel key={0} onClick={() => setShowPrintModal(false)} />]}
            maskTransitionName=""
          >
            <table className="ant-table w-100">
              <tbody className="ant-table-tbody">{renderPrintRow()}</tbody>
            </table>
          </Modal>
        ) : null}
      </>
    )
  }
  return (
    <ReactToPrint
      content={content}
      removeAfterPrint={removeAfterPrint}
      trigger={() => {
        return (
          <Dropdown.Button overlay={menu} trigger={['click']} icon={<DownOutlined />}>
            <PrinterOutlined /> Print
          </Dropdown.Button>
        )
      }}
    />
  )
}

export default injectIntl(PrintDropdown)
