import { message } from 'antd'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import {
  getFinanceContact,
  getFinanceContactMoneyInOutStat,
  getFinanceContacts,
  getFinanceContactSalesStats,
  getFinanceContactTransactions,
  getSuggestionFinanceContact,
} from 'utils/apis'
import { optionsSelector } from 'redux/selectors'
import { editOption } from 'redux/options/actions'

export const useContactsSuggestion = (payload, apiOptions, RQOptions) => {
  return useQuery(
    ['contacts-suggestion', payload],
    async () => {
      const {
        data: { data },
      } = await getSuggestionFinanceContact(payload, apiOptions)
      return data
    },
    {
      ...RQOptions,
      keepPreviousData: true,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export const useContacts = ({ payload = {}, options = {} } = {}) => {
  const dispatch = useDispatch()
  const optionsState = useSelector(optionsSelector)

  // prevent user that already setting per_page > 100
  if (payload.per_page > 100) {
    payload.per_page = 100
  }

  if (payload.per_page && payload.per_page !== optionsState?.contacts_per_page_default) {
    dispatch(
      editOption({
        contacts_per_page_default: payload.per_page,
      }),
    )
  }

  return useQuery(
    ['contact', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceContacts(payload)
      return data
    },
    {
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export function useContact({ id, enabled = false }) {
  return useQuery(
    ['contact', id],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceContact(id)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export function useContactTransaction(params = {}, options = {}) {
  const { id } = params
  return useQuery(
    [`contact/${id}/transactions`, params],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceContactTransactions(id, params)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      // enabled: !!id && enabled,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useContactMoneyInOut(id, params = {}, options = {}) {
  return useQuery(
    ['contactMoneyInOut', id, params],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceContactMoneyInOutStat(id, params)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useContactSalesStats({ id, params = {}, options = {} }) {
  return useQuery(
    ['contactSalesStats', id, params],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceContactSalesStats(id, params)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useInfiniteContactSuggestion = (payload, options = {}, apiOptions = {}) => {
  return useInfiniteQuery(
    ['infinite/contacts/suggestion', payload],
    async ({ pageParam = 0 }) => {
      let response
      try {
        const {
          data: { data },
        } = await getSuggestionFinanceContact({ page: pageParam, ...payload }, apiOptions)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined
        }
        return lastPage.current_page < lastPage.last_page ? lastPage.current_page + 1 : undefined
      },
      onError: (error) => message.error(error.message || 'Failed to load data from server!'),
      keepPreviousData: true,
      ...options,
    },
  )
}
