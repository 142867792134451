import React from 'react'
import { injectIntl } from 'react-intl'
import { Col, Row } from 'antd'

import { currency, formatDate } from 'utils/formatting'
import Charts from 'components/apps/Reports/AccountTransactions/Charts'

class PrintAccountTransactionReport extends React.Component {
  render() {
    const { data, header, columns, intl, journalTotal, params, getParams, showGraph } = this.props
    const formattedData = Object.keys(data) || []
    const headerColumns = columns.map((item) => {
      return (
        <th key={item.key} style={{ textAlign: item.textAlign === 'right' ? 'right' : 'left' }}>
          {item.title}
        </th>
      )
    })
    const body = []

    formattedData.forEach((item, index) => {
      body.push(
        <React.Fragment key={data[item]?.id || data[item]?.name}>
          {params?.isCategory || params?.cashFlowDetail ? (
            <tr className="ant-table-row">
              <td colSpan={7}>
                <div style={{ display: 'flex', alignItems: 'baseline', marginTop: 10 }}>
                  <h3 style={{ paddingRight: 15 }}>{data[item].name || '-'}</h3>
                  <h4>({data[item].ref_code || '-'})</h4>
                </div>
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={4}>
                <h5>{intl.formatMessage({ id: 'report.opening_balance' })}</h5>
              </td>
              <td style={{ textAlign: 'right' }}>
                <h5>
                  {data[item].opening_credit > -1
                    ? currency(data[item].opening_credit, false, true, true)
                    : `(${currency(data[item].opening_credit, false, true, true)})`.replace(
                        '-',
                        '',
                      )}
                </h5>
              </td>
              <td style={{ textAlign: 'right' }}>
                <h5>
                  {data[item].opening_debit > -1
                    ? currency(data[item].opening_debit, false, true, true)
                    : `(${currency(data[item].opening_debit, false, true, true)})`.replace('-', '')}
                </h5>
              </td>
              <td style={{ textAlign: 'right' }}>
                <h5>
                  {data[item].opening_balance > -1
                    ? currency(data[item].opening_balance, false, true, true)
                    : `(${currency(data[item].opening_balance, false, true, true)})`.replace(
                        '-',
                        '',
                      )}
                </h5>
              </td>
            </tr>
          )}

          {(data[item].data || []).map((v) => {
            return (
              <tr key={v.id} className="ant-table-row cursor">
                <td>{formatDate(v.trans_date)}</td>
                <td>{v.source}</td>
                <td>{v.desc}</td>
                <td>{v.reference}</td>
                <td>{v.ref_number}</td>
                <td style={{ textAlign: 'right' }}>
                  {v.debit > -1
                    ? currency(v.debit, false, true, true)
                    : `(${currency(v.debit, false, true, true)})`.replace('-', '')}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {v.credit > -1
                    ? currency(v.credit, false, true, true)
                    : `(${currency(v.credit, false, true, true)})`.replace('-', '')}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {v.balance > -1
                    ? currency(v.balance, false, true, true)
                    : `(${currency(v.balance, false, true, true)})`.replace('-', '')}
                </td>
              </tr>
            )
          })}
          <tr>
            <td colSpan={5}>
              <h5>{intl.formatMessage({ id: 'report.closing_balance' })}</h5>
            </td>
            <td style={{ textAlign: 'right' }}>
              <h5>
                {data[item].closing_debit > -1
                  ? currency(data[item].closing_debit, false, true, true)
                  : `(${currency(data[item].closing_debit, false, true, true)})`.replace('-', '')}
              </h5>
            </td>
            <td style={{ textAlign: 'right' }}>
              <h5>
                {data[item].closing_credit > -1
                  ? currency(data[item].closing_credit, false, true, true)
                  : `(${currency(data[item].closing_credit, false, true, true)})`.replace('-', '')}
              </h5>
            </td>
            <td style={{ textAlign: 'right' }}>
              <h5>
                {data[item].closing_balance > -1
                  ? currency(data[item].closing_balance, false, true, true)
                  : `(${currency(data[item].closing_balance, false, true, true)})`.replace('-', '')}
              </h5>
            </td>
          </tr>
          {(Object.keys(data) || []).length !== index + 1 && (
            <tr>
              <td colSpan={7} />
            </tr>
          )}
        </React.Fragment>,
      )
    })

    body.push(
      <React.Fragment key="top-list">
        <tr>
          <td colSpan={7} />
        </tr>
        <tr>
          <td colSpan={5}>
            <h5>{intl.formatMessage({ id: 'report.total' })}</h5>
          </td>
          <td style={{ textAlign: 'right' }}>
            <h5>{currency(journalTotal.total_debit || 0, false, true, true)}</h5>
          </td>
          <td style={{ textAlign: 'right' }}>
            <h5>{currency(journalTotal.total_credit || 0, false, true, true)}</h5>
          </td>
          <td />
        </tr>
      </React.Fragment>,
    )

    return (
      <div className="print">
        <section>
          <Row style={{ marginBottom: '5mm' }}>
            <Col span={24}>{header}</Col>
          </Row>
          {showGraph ? <Charts params={getParams()} isPrint /> : null}
          <Row>
            <Col span={24}>
              <Row style={{ marginTop: '10mm' }}>
                <Col span={24}>
                  <table cellPadding="5" cellSpacing="5" style={{ width: '100%', border: '0px' }}>
                    <thead style={{ borderBottom: '1px solid #000000' }}>
                      <tr>{headerColumns}</tr>
                    </thead>
                    <tbody>{body}</tbody>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      </div>
    )
  }
}

export default injectIntl(PrintAccountTransactionReport)
