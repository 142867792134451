import React from 'react'
import { ConfigProvider } from 'antd'
import { addLocaleData, IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/id'
import { ThemeProvider } from 'styled-components'

import english from 'locales/en-US'
import indonesia from 'locales/id-ID'
import { styledComponentsTheme } from 'styledComponentsTheme'

addLocaleData(english.localeData)
addLocaleData(indonesia.localeData)

const locales = {
  'en-US': english,
  'id-ID': indonesia,
}

@connect(({ settings }) => ({ settings }))
class Localization extends React.Component {
  componentDidMount() {
    this.setLocale()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.settings.locale !== this.props.settings.locale) {
      this.setLocale()
    }
  }

  setLocale = () => {
    if (this.props.settings.locale === 'id-ID') {
      moment.locale('id')
    } else {
      moment.locale('en')
    }
  }

  render() {
    const {
      children,
      settings: { locale },
    } = this.props
    const currentLocale = locales[locale]
    return (
      <ConfigProvider locale={currentLocale.antdData}>
        <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
          <ThemeProvider theme={styledComponentsTheme}>{children}</ThemeProvider>
        </IntlProvider>
      </ConfigProvider>
    )
  }
}

export default Localization
