import { message } from 'antd'
import { push } from 'connected-react-router'
import localStore from 'store'
import _ from 'lodash'

import { getInits, getInitsPublic } from 'utils/apis'
import { removeToken } from 'utils/cookies'
import * as actionTypes from 'redux/actionTypes'
// import {posStore} from '../posSettings/actions';
import { setFinance } from 'redux/finance/actions'
import { setBroadcast } from 'redux/broadcast/actions'
import { changeSetting } from 'redux/settings/actions'
import { setTutorials } from 'redux/tutorials/actions'
import { initialState as initialStateUser } from 'redux/user/reducers'
import { setSelectedEndpoint } from 'redux/companies/actions'
import { hideLoginForm } from 'redux/user/actions'
import { setDefaultMessage } from 'redux/defaultMessage/actions'
import { setCustomColumns } from 'redux/customColumn/actions'
import { setCurrencies } from 'redux/currencies/actions'
import { setOutlets } from 'redux/outlets/actions'

export const startLoading = () => {
  return {
    type: actionTypes.START_LOADING,
  }
}

export const stopLoading = () => {
  return {
    type: actionTypes.STOP_LOADING,
  }
}

export const startInit = () => {
  return {
    type: actionTypes.START_INIT,
  }
}

export const stopInit = () => {
  return {
    type: actionTypes.STOP_INIT,
  }
}

export const setLoadedInit = (payload = true) => {
  return {
    type: actionTypes.SET_LOADED_INIT,
    payload,
  }
}

export const increaseAttemp = () => {
  return {
    type: actionTypes.INCREASE_ATTEMP,
  }
}

export const resetAttemp = () => {
  return {
    type: actionTypes.RESET_ATTEMP,
  }
}

export const setRefs = (payload) => {
  return {
    type: actionTypes.SET_REFS,
    payload,
  }
}

export const setPayments = (payload) => {
  return {
    type: actionTypes.SET_PAYMENTS,
    payload,
  }
}

export const setUser = (payload) => {
  return {
    type: actionTypes.SET_USER,
    payload,
  }
}

export const setMenu = (payload) => {
  return {
    type: actionTypes.SET_MENU,
    payload,
  }
}

export const setOptions = (payload) => {
  return {
    type: actionTypes.SET_OPTIONS,
    payload,
  }
}

export const setBilling = (payload) => {
  return {
    type: actionTypes.SET_BILLING,
    payload,
  }
}

export const setOnboardingChecklist = (payload) => {
  return {
    type: actionTypes.SET_ONBOARDING_CHECKLIST,
    payload,
  }
}

export const setCompanies = (payload) => {
  return {
    type: actionTypes.SET_COMPANIES,
    payload,
  }
}

export const setTranslation = (payload) => {
  return {
    type: actionTypes.SET_TRANSLATION,
    payload,
  }
}

export const setNPS = (payload) => {
  return {
    type: actionTypes.SET_NPS,
    payload,
  }
}

export const setReviews = (payload) => {
  return {
    type: actionTypes.SET_REVIEWS,
    payload,
  }
}

export const setAnnouncement = (payload) => {
  return {
    type: actionTypes.SET_ANNOUNCEMENT,
    payload,
  }
}

export const setSalutations = (payload) => {
  return {
    type: actionTypes.SET_SALUTATIONS,
    payload,
  }
}

export const setReasons = (payload) => {
  return {
    type: actionTypes.SET_REASONS,
    payload,
  }
}

export const setHris = (payload) => {
  return {
    type: actionTypes.SET_SHOW_HR_ADDON,
    payload,
  }
}

export const setInits = (data) => {
  return (dispatch, getState) => {
    if (data.user) {
      dispatch(setUser(data))
    }
    if (!_.isEmpty(data.companies)) {
      dispatch(
        changeSetting({
          setting: 'locale',
          value: data.user.lang,
        }),
      )
      dispatch(setMenu(data.user.menus))
      dispatch(
        setCompanies({
          companies: data.companies,
          website_id: data.website_id,
          endpoint: data.endpoint,
        }),
      )

      // Jika belum ada endpoint disetkan dari init
      if (
        data.endpoint &&
        data.endpoint !== localStore.get('app.endpoint') &&
        !localStore.get('app.endpoint')
      ) {
        if (getState()?.router?.location?.pathname !== '/user/select-company') {
          dispatch(setSelectedEndpoint(data.endpoint))
        }
      }
      if (typeof data.show_nps !== 'undefined') {
        dispatch(setNPS(data.show_nps))
      }

      if (typeof data.show_hr_addon !== 'undefined') {
        dispatch(setHris(data.show_hr_addon))
      }
      if (data.tutorials) {
        dispatch(setTutorials(data.tutorials))
      }
      if (data.message_default) {
        dispatch(setDefaultMessage(data.message_default))
      }
      if (data.translation) dispatch(setTranslation(data.translation))
      if (data.lang) dispatch(setRefs(data))
      if (data.payments) dispatch(setPayments(data))
      if (data.options) dispatch(setOptions(data))
      if (data.finance) dispatch(setFinance(data.finance, data.translation))
      if (data.billing) dispatch(setBilling(data.billing))
      if (data.onboarding_checklist) dispatch(setOnboardingChecklist(data.onboarding_checklist))
      if (data.broadcast) {
        dispatch(setBroadcast(data.broadcast))
      }
      if (data.custom_columns) {
        dispatch(
          setCustomColumns({
            custom_column_types: data.custom_column_types,
            custom_columns: data.custom_columns,
            custom_column_items: data.custom_column_items,
          }),
        )
      }
      if (data.currencies) {
        dispatch(setCurrencies(data.currencies))
      }
      if (data.announcement) {
        dispatch(setAnnouncement(data.announcement))
      }
      if (data.salutations) {
        dispatch(setSalutations(data.salutations))
      }
      if (data.reasons) {
        dispatch(setReasons(data.reasons))
      }
      if (data.outlets) {
        dispatch(setOutlets(data.outlets))
      }
    }

    dispatch(setLoadedInit())
  }
}

export const inits = ({ callback } = {}) => {
  return async (dispatch, getState) => {
    const state = getState()
    await dispatch(startInit())

    return getInits()
      .then((response) => {
        if (response.data.success) {
          dispatch(setInits(response.data.data))
          dispatch(stopInit())
          if (typeof callback === 'function') callback()
        } else {
          message.error(response.data.message)
          dispatch(stopInit())
        }
        return true
      })
      .catch((e) => {
        if (e?.status !== 521) {
          if (e?.status !== 401) {
            const maxAttemp = 4
            if (state.inits.attemp === maxAttemp) {
              dispatch(push('/server-error'))
            } else {
              dispatch(increaseAttemp())
            }
          } else {
            removeToken()
            dispatch(resetInits())
            dispatch(push('/user/login'))
            dispatch(hideLoginForm())
          }
        }

        return false
      })
      .finally(() => {
        dispatch(stopInit())
      })
  }
}

export const initsPublic = () => {
  return (dispatch) => {
    dispatch(startInit())

    return getInitsPublic()
      .then((response) => {
        if (response.data.success) {
          const { data } = response.data
          dispatch(setReviews(data.quotes))
          dispatch(
            changeSetting({
              setting: 'locale',
              value: data.options.property_language || 'id-ID',
            }),
          )
          dispatch(setOptions(data))
          dispatch(stopInit())
        } else {
          message.error(response.data.message)
          dispatch(stopInit())
        }
        return true
      })
      .catch(() => {
        dispatch(stopInit())
        return false
      })
  }
}

// Reset redux ketika logout
export const resetInits = () => {
  return (dispatch) => {
    dispatch(setUser({ user: initialStateUser }))
    dispatch(setMenu({ dashboard: [], settings: [] }))
    dispatch(
      setCompanies({
        companies: [],
        website_id: null,
        endpoint: null,
      }),
    )
    dispatch(setSelectedEndpoint(null))
    dispatch(setNPS(0))
    dispatch(setHris(0))
    dispatch(setRefs({ lang: [] }))
    dispatch(setPayments({ payments: [] }))
    dispatch(
      setCustomColumns({
        custom_column_types: [],
        custom_columns: [],
        custom_column_items: [],
      }),
    )
    dispatch(
      setFinance({
        accountCategories: [],
        bankAccounts: [],
        config: {},
        finance_approval: {},
        dashboard: [],
        expenseTerms: [],
        productCategories: [],
        product_categories_has_role: [],
        shippingComps: [],
        taxes: [],
        units: [],
        warehouses: [],
      }),
    )
    dispatch(setSalutations([]))
    dispatch(setReasons([]))
    dispatch(setOutlets([]))
  }
}
