import { createSelector } from 'reselect'
import _ from 'lodash'
import { parse } from 'query-string'

export const initsSelector = createSelector(
  (state) => state.inits,
  (inits) => inits,
)
export const loadedInitSelector = createSelector(
  (state) => state.inits.loadedInit,
  (loadedInit) => loadedInit,
)
export const mobileViewSelector = createSelector(
  (state) => state.settings.isMobileView,
  (isMobileView) => isMobileView,
)
export const settingsSelector = createSelector(
  (state) => state.settings,
  (settings) => settings,
)
export const tabViewSelector = createSelector(
  (state) => state.settings.isTabView,
  (isTabView) => isTabView,
)
export const warehousesSelector = createSelector(
  (state) => state.finance.warehouse.warehouses,
  (warehouses) => warehouses,
)
export const warehousesHasRoleSelector = createSelector(
  (state) => state.finance.warehouse.warehouses_has_role,
  (warehouses) => warehouses,
)
export const taxesSelector = createSelector(
  (state) => state.finance.tax.taxes,
  (taxes) => taxes,
)
export const tutorialsSelector = createSelector(
  (state) => state.tutorials,
  (tutorials) => tutorials,
)
export const activeTaxesSelector = createSelector(taxesSelector, (taxes) =>
  taxes.filter((tax) => tax.is_active),
)
export const optionsSelector = createSelector(
  (state) => state.options.options,
  (options) => options,
)
export const shippingCompsSelector = createSelector(
  (state) => state.finance.shippingComp.shippingComps,
  (shippingComps) => shippingComps,
)
export const productCategoriesSelector = createSelector(
  (state) => state.finance.productCategories,
  (productCategories) => productCategories,
)
export const titleSelector = createSelector(
  (state) => state.translation,
  (__, name) => name,
  (translation, name) => (translation.menu ? translation.menu[name] : ''),
)
export const reportTitleSelector = createSelector(
  (state) => state.translation,
  (__, name) => name,
  (translation, name) => (translation.reportmenu ? translation.reportmenu[name] : ''),
)
export const termTitleSelector = createSelector(
  (state) => state.translation,
  (translation) => translation?.term,
)
export const financeConfigSelector = createSelector(
  (state) => state.finance.config,
  (config) => config,
)
export const desktopLayoutSelector = createSelector(
  (state) => state.settings,
  (settings) => !settings.isMobileView && !settings.isTabView,
)
export const auditModalSelector = createSelector(
  (state) => state.finance.auditModal,
  (auditModal) => auditModal,
)
export const accountTransReportSelector = createSelector(
  (state) => state.finance.accountTransactionReport,
  (accountTransactionReport) => accountTransactionReport,
)
export const locationSelector = createSelector(
  (state) => state.router.location,
  (location) => location,
)
export const termSelector = createSelector(
  (state) => state.finance.expenseTerm,
  (expenseTerm) => expenseTerm,
)
export const findTermSelector = createSelector(
  (state) => state.finance.expenseTerm.expenseTerms,
  (__, id) => id,
  (terms, id) => terms.find((tax) => tax.id === id),
)
export const activeTermsSelector = createSelector(
  (state) => state.finance.expenseTerm.expenseTerms,
  (terms) => terms.filter((term) => term.is_active),
)
export const countActiveTermSelector = createSelector(activeTermsSelector, (terms) => terms.length)
export const canDeletedTermsSelector = createSelector(
  (state) => state.finance.expenseTerm.canDeletedExpenseTerms,
  (canDeletedExpenseTerms) => canDeletedExpenseTerms,
)
export const firstTermSelector = createSelector(activeTermsSelector, (terms) => _.first(terms))
export const firstWarehouseSelector = createSelector(warehousesHasRoleSelector, (warehouses) => {
  const unArchiveWarehouses = (warehouses || []).filter((row) => row.is_archive !== 1)
  if (Array.isArray(unArchiveWarehouses) && unArchiveWarehouses.length > 0) {
    return unArchiveWarehouses[0]
  }

  return {}
})
export const unitsSelector = createSelector(
  (state) => state.finance.unit.units,
  (units) => units,
)
export const broadcastSelector = createSelector(
  (state) => state.broadcast,
  (broadcast) => broadcast,
)
export const showNPSSelector = createSelector(
  (state) => state.show_nps,
  (show_nps) => show_nps,
)
export const billingSelector = createSelector(
  (state) => state.billing,
  (billing) => billing,
)
export const userSelector = createSelector(
  (state) => state.user,
  (user) => user,
)
export const loginFormSelector = createSelector(
  (state) => state.loginForm,
  (loginForm) => loginForm,
)
export const companiesSelector = createSelector(
  (state) => state.companies.companies,
  (companies) => companies,
)
export const selectedEndpointSelector = createSelector(
  (state) => state.companies.selectedEndpoint,
  (selectedEndpoint) => selectedEndpoint,
)
export const hiddenFeatureSelector = createSelector(
  (state) => state.billing.hidden_feature,
  (hidden_feature) => hidden_feature,
)
export const contactGroupsSelector = createSelector(
  (state) => state.finance.contactGroups,
  (contactGroups) => contactGroups,
)
export const defaultMessageSelector = createSelector(
  (state) => state.defaultMessage,
  (defaultMessage) => defaultMessage,
)
export const customColumnSelector = createSelector(
  (state) => state.customColumn,
  (customColumn) => customColumn,
)
export const hrisSelector = createSelector(
  (state) => state.show_hr_addon,
  (show_hr_addon) => show_hr_addon,
)
export const multiCurrencySelector = createSelector(
  (state) => state.multiCurrency,
  (multiCurrency) => multiCurrency,
)
export const contactIdCardTypeSelector = createSelector(
  (state) => state.finance.idCardTypes,
  (idCardTypes) => idCardTypes,
)
export const optionSelector = createSelector(
  (state) => state.options.options?.option,
  (option) => option,
)
export const companySelector = createSelector(
  (state) => state.companies,
  (companies) => companies,
)
export const bankAccountsSelector = createSelector(
  (state) => state.finance?.bankAccounts || [],
  (bankAccounts) => bankAccounts,
)
export const tempTransKeySelector = createSelector(
  (state) => state.router.location,
  (location) => {
    const searchs = parse(location.search)
    return searchs?.tempTransKey || ''
  },
)
export const websiteIdSelector = createSelector(
  (state) => state.companies.website_id,
  (websiteId) => websiteId,
)
export const companyLogoSelector = createSelector(
  (state) => state.options.options.property_logo,
  (companyLogo) => companyLogo,
)

export const companyDetailsSelector = createSelector(
  (state) => {
    const { options } = state.options
    return {
      property_name: options.property_name,
      property_email: options.property_email,
      property_phone: options.property_phone,
      property_address: options.property_address,
      property_address_delivery: options.property_address_delivery,
      property_country_id: options.property_country_id,
      property_npwp: options.property_npwp,
    }
  },
  (companyDetails) => companyDetails,
)

export const productCategoriesHasRolesSelector = createSelector(
  (state) => state.finance.product_categories_has_role,
  (productCategoriesHasRoles) => productCategoriesHasRoles,
)
export const financeApprovalSelector = createSelector(
  (state) => state.finance.financeApproval,
  (options) => options,
)
export const maintenanceSelector = createSelector(
  (state) => state.maintenance,
  (maintenance) => maintenance,
)

export const translationReportmenuSelector = createSelector(
  (state) => state.translation.reportmenu,
  (reportmenu) => reportmenu,
)

export const translationTermSelector = createSelector(
  (state) => state.translation.term,
  (term) => term,
)

export const translationSelector = createSelector(
  (state) => state.translation,
  (translation) => translation,
)

export const accountCategoriesSelector = createSelector(
  (state) => state.finance.accountCategories,
  (accountCategories) => accountCategories,
)

export const cashBankAccountCategoriesSelector = createSelector(
  [accountCategoriesSelector],
  (accountCategories) => {
    const CASH_BANK = 1
    const CREDIT_CARD = 17
    return accountCategories.filter((row) => _.includes([CASH_BANK, CREDIT_CARD], row.id))
  },
)

export const isMultiCurrencySelector = createSelector(
  (state) => state.options?.options?.multi_currency === 1,
  (isMultiCurrency) => isMultiCurrency,
)

export const baseMultiCurrencySelector = createSelector(
  (state) => {
    const currencies = state.multiCurrency?.currencies
    const options = state.options?.options || {}
    if (!Array.isArray(currencies) && options.multi_currency !== 1) {
      return {}
    }
    const findCurrency = currencies.find((item) => item.id === options.property_currency_id)
    return findCurrency || {}
  },
  (baseCurrency) => baseCurrency,
)

export const sortByCashBankSelector = createSelector(
  (state) => state.options?.options?.bank_account_sort_by,
  (bankAccountSortBy) => bankAccountSortBy,
)

export const dashboardSelector = createSelector(
  (state) => state.finance?.dashboard || [],
  (dashboard) => dashboard,
)

export const makeCashBanksResults = () =>
  createSelector(
    [bankAccountsSelector, dashboardSelector, optionsSelector, multiCurrencySelector],
    (bankAccounts, dashboard, options, multiCurrency) => (search) => {
      const {
        bank_account_sort_by: bankAccountSortBy,
        multi_currency: multiCurrencyOption,
        property_currency_symbol: propertyCurrencySymbol,
      } = options
      const currencies = multiCurrency?.currencies || []
      const isMultiCurrency = multiCurrencyOption === 1
      const searchToLowerCase = search.toLowerCase()
      // Pertama urutkan bank accounts berdasarkan pengaturan di option
      const sortedBankAccounts = _.sortBy(bankAccounts, bankAccountSortBy || 'ref_code')
      // Kemudian cari berdasarkan parameter search dan diambil dasboard id nya
      const data = sortedBankAccounts
        .filter((result) => {
          return (
            result.name?.toLowerCase()?.includes(searchToLowerCase) ||
            result.ref_code?.toLowerCase()?.includes(searchToLowerCase)
          )
        })
        .map((row) => {
          // Selanjutnya dicarikan datanya ke dasboard
          const findData = dashboard.find((item) => item.id === row.dashboard_id)
          const newRow = {
            ...row,
            currencySymbol: propertyCurrencySymbol,
            closing_balance: findData?.data?.closing_balance || 0,
            statement_balance: findData?.data?.statement_balance || 0,
            graph: findData?.data?.graph || {},
            loaded: findData?.loaded || false,
            daterange: findData?.daterange || '',
            lastCache: findData?.lastCache || 0,
            cache: findData?.cache || '',
          }
          if (isMultiCurrency && !!row.currency_id) {
            const findMultiCurrency = currencies.find((currency) => currency.id === row.currency_id)
            newRow.currencySymbol = findMultiCurrency?.symbol || propertyCurrencySymbol
          }
          return newRow
        })
      const isLoaded = _.some(data, (item) => item.loaded === true)

      return { data, bankAccountSortBy, isMultiCurrency, isLoaded }
    },
  )
