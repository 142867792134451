import React from 'react'
import { injectIntl } from 'react-intl'
import { Typography } from 'antd'

import { ChartCardWithFilter } from 'components/UI/index'
import styles from './widget-trend-up-down.module.scss'

const WidgetTrendUpDown = ({
  title,
  params,
  onParamsChange,
  loading,
  amount,
  numOfTransaction,
  percent,
  rawPercent,
  descriptionBottomRight,
  percentStyle = {},
  cardProps = {},
  renderCustomChart: CustomChart,
  showStatistic = true,
  cardHeaderMarginBottom,
  extra,
  cardClassName,
  statisticContainerClassName,
  amountClassName,
  numOfTransactionClassName,
  statisticClassName,
  percentClassName,
  descriptionClassName,
  widgetContainerClassName,
  titleProps,
}) => {
  return (
    <ChartCardWithFilter
      loading={loading}
      title={title}
      params={params}
      onParamsChange={onParamsChange}
      cardHeaderMarginBottom={cardHeaderMarginBottom}
      extra={extra}
      cardClassName={cardClassName}
      titleProps={titleProps}
      {...cardProps}
    >
      <div className={[styles.container, widgetContainerClassName].join(' ')}>
        <div className="mt-2">
          <span className={[styles.amount, amountClassName].join(' ')}>{amount}</span>
          <Typography.Paragraph
            className={[styles.numOfTransactions, numOfTransactionClassName].join(' ')}
          >
            {numOfTransaction}
          </Typography.Paragraph>
        </div>
        {showStatistic ? (
          <div className={[styles.statistic, statisticContainerClassName].join(' ')}>
            {typeof CustomChart === 'function' ? (
              <CustomChart />
            ) : (
              <div
                style={{
                  width: 56,
                  height: 34,
                }}
                className={statisticClassName}
              >
                <svg
                  style={{
                    width: 56,
                    height: 34,
                    transform: rawPercent >= 0 ? undefined : 'scaleX(-1) rotate(180deg)',
                  }}
                  width="56"
                  height="34"
                  viewBox="0 0 56 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M39 0.5L45.2975 6.7975L31.8775 20.2175L20.8775 9.2175L0.5 29.6225L4.3775 33.5L20.8775 17L31.8775 28L49.2025 10.7025L55.5 17V0.5H39Z"
                    fill={rawPercent >= 0 ? '#219653' : '#E53F6A'}
                  />
                </svg>
              </div>
            )}
            <Typography.Paragraph
              style={{
                color: rawPercent >= 0 ? '#219653' : '#E53F6A',
                ...percentStyle,
              }}
              className={[styles.percent, percentClassName].join(' ')}
            >
              {percent}%
            </Typography.Paragraph>
          </div>
        ) : (
          <div className={styles.statistic} />
        )}
      </div>
      {descriptionBottomRight ? (
        <Typography.Paragraph
          className={[styles.descriptionBottomRight, descriptionClassName].join(' ')}
        >
          {descriptionBottomRight}
        </Typography.Paragraph>
      ) : null}
    </ChartCardWithFilter>
  )
}

export default injectIntl(WidgetTrendUpDown)
