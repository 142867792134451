import React from "react";
import moment from "moment/moment";
import { billingSelector, optionsSelector } from 'redux/selectors';
import { editOption } from 'redux/options/actions'
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Alert } from "antd";
import { checkPermission } from 'redux/user/selectors';

const getTrialMessage = (remainingDays, isHasAccess) => {
  const hoursUntilMidnight = moment().endOf("day").diff(moment(), "hours");
  const upgradeLink = (
    <Link to={isHasAccess ? "/settings/billing/upgrade" : "/faqs/detail/205"}>
      <strong>
        <FormattedMessage id="billing.upgrade_now" />
      </strong>
    </Link>
  );
  const commonValues = {
    setup: upgradeLink,
  };

  if (remainingDays === 0) {
    return (
      <FormattedMessage id="setup.subscription_zero_days" values={{ setup: upgradeLink }} />
    );
  }

  if (remainingDays === 1) {
    return (
      <FormattedMessage
        id="setup.subscription_one_day"
        values={{ ...commonValues, hour: hoursUntilMidnight }}
      />
    );
  }

  return (
    <FormattedMessage
      id="setup.subscription"
      values={{ ...commonValues, days: remainingDays }}
    />
  );
};

const SubscriptionWarning = ({ showIcon = true, style, ...props }) => {
  const { plan, expiry_date } = useSelector(billingSelector);
  const { demo, expired_warning_closed } = useSelector(optionsSelector);
  const dispatch = useDispatch()
  const remainingDays = moment(expiry_date).diff(moment(), 'days');
  const isHasAccess = useSelector((state) => checkPermission(state, 'billing'));

  if (!plan || !expiry_date || (plan && plan.includes("Trial") || demo === 0) || (expired_warning_closed === 1 && remainingDays > 3) || remainingDays < 0 || remainingDays > 14) {
    return null;
  }

  const alertMessage = getTrialMessage(remainingDays, isHasAccess);

  const onAlertClose = () => {
    dispatch(
      editOption({
        expired_warning_closed: 1,
      }),
    )
  }

  return (
    <Alert
      className="notification-demo hide-on-print"
      style={{ backgroundColor: "#ffefb3", ...style }}
      message={alertMessage}
      type="warning"
      closable={remainingDays > 3}
      onClose={onAlertClose}
      showIcon={showIcon}
      {...props}
    />
  );
};

export default SubscriptionWarning;
