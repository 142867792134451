import { message } from 'antd'
import { useInfiniteQuery, useMutation, useQuery } from 'react-query'

import {
  addFinanceAccount,
  addFinanceAccountRoles,
  getFinanceAccount,
  getFinanceAccountRoles,
  getFinanceAccounts,
  getFinanceAccountsStats,
  getSuggestionFinanceAccount,
  getSuggestionFinanceParentAccount,
} from 'utils/apis/financeAccount'

export const useAccountsSuggestion = (payload, apiOptions, RQOptions) => {
  return useQuery(
    ['accounts-suggestion', payload],
    async () => {
      const {
        data: { data },
      } = await getSuggestionFinanceAccount(payload, apiOptions)
      return data
    },
    {
      ...RQOptions,
      keepPreviousData: true,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export const useInfiniteAccountSuggestion = (payload, withParent, options = {}) => {
  const request = withParent ? getSuggestionFinanceParentAccount : getSuggestionFinanceAccount
  const queryKey = withParent
    ? 'infinite/accounts/parent/suggestion'
    : 'infinite/accounts/suggestion'
  return useInfiniteQuery(
    [queryKey, payload],
    async ({ pageParam = 0 }) => {
      let response
      try {
        const {
          data: { data },
        } = await request({ page: pageParam, ...payload })
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined
        }
        return lastPage.current_page < lastPage.last_page ? lastPage.current_page + 1 : undefined
      },
      onError: (error) => message.error(error.message || 'Failed to load data from server!'),
      keepPreviousData: true,
      ...options,
    },
  )
}

export const useAccounts = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['account', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceAccounts(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export const useAccountsStats = ({ payload = {}, options }) => {
  return useQuery(
    ['accounts-stats', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceAccountsStats(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useAccount = ({ id = null, enabled = false, onSuccess }) => {
  return useQuery(
    ['account', id],
    async () => {
      const {
        data: { data },
      } = await getFinanceAccount(id)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      onSuccess,
    },
  )
}

export const useAccountRoles = (payload, options = {}) => {
  return useQuery(
    [`/accounts/${payload.id}/roles`],
    async () => {
      const {
        data: { data },
      } = await getFinanceAccountRoles(payload.id)
      return data
    },
    {
      enabled: !!payload.id,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useAddAccountRoles = () => {
  return useMutation((payload) => addFinanceAccountRoles(payload.id, payload))
}

export const useAddAccount = () => {
  return useMutation((payload) => addFinanceAccount(payload))
}
