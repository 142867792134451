import React from 'react'
import { injectIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { Modal } from 'antd'

import { checkPermission } from 'redux/user/selectors'
import { Cancel, Upgrade } from 'components/UI'
import { isFeatureChampion, isFeatureElite } from 'utils/helper'
import { billingSelector } from 'redux/selectors'
import './style.scss'

const UpgradeBilling = injectIntl(({ label, intl }) => {
  const isHasAccess = useSelector((state) => checkPermission(state, 'billing'))
  const dispatch = useDispatch()

  const onPush = (path) => dispatch(push(path))

  return (
    <div className="text-center text-wrapper">
      <h5 className="text-center mb-3">{label}</h5>
      {isHasAccess && (
        <Upgrade
          title={intl.formatMessage({ id: 'billing.upgrade_now' })}
          onClick={() => onPush('/settings/billing/upgrade')}
        />
      )}
    </div>
  )
})

// Disable hidden feature
const HideFeature = (props) => {
  const {
    code = '',
    feature = '',
    intl,
    currentCount,
    maxCode,
    maxFeature = '',
    forceHiddenFeature,
    isFeatureOnlyAccess,
    asModal,
    modalProps = {},
    children = null,
  } = props

  const billing = useSelector(billingSelector)

  if (forceHiddenFeature) return children

  let label = intl.formatMessage(
    {
      id: isFeatureOnlyAccess
        ? 'billing.feature_can_only_be_use_on_pro_plan'
        : 'billing.feature_available_in_pro_plan',
    },
    { feature },
  )
  let modalTitle = intl.formatMessage({ id: 'billing.pro_plan_features' })

  if (_.isEmpty(billing)) return children

  if (billing.can_be_addoned && maxCode && billing[maxCode] <= currentCount) {
    label = intl.formatMessage(
      { id: 'billing.quota_is_exhausted_add_an_addon' },
      { feature: maxFeature },
    )
  } else if (
    isFeatureChampion({
      billing,
      code,
    })
  ) {
    label = intl.formatMessage(
      {
        id: isFeatureOnlyAccess
          ? 'billing.feature_can_only_be_use_on_champion_plan'
          : 'billing.feature_available_in_champion_plan',
      },
      { feature },
    )
    modalTitle = intl.formatMessage({ id: 'billing.champion_plan_features' })
  } else if (
    isFeatureElite({
      billing,
      code,
    })
  ) {
    label = intl.formatMessage(
      {
        id: isFeatureOnlyAccess
          ? 'billing.feature_can_only_be_use_on_elite_plan'
          : 'billing.feature_available_in_elite_plan',
      },
      { feature },
    )
    modalTitle = intl.formatMessage({ id: 'billing.elite_plan_features' })
  } else if (billing.hidden_feature?.filter((e) => e === code).length === 0) {
    return children
  }

  if (asModal) {
    return (
      <Modal
        title={modalTitle}
        footer={[<Cancel key={0} onClick={() => modalProps?.onCancel?.()} />]}
        maskTransitionName=""
        {...modalProps}
      >
        <UpgradeBilling label={label} />
      </Modal>
    )
  }

  return (
    <div className="hide-feature-container">
      {children}
      <div className="wrapper">
        <UpgradeBilling label={label} />
      </div>
    </div>
  )
}

export default injectIntl(HideFeature)
