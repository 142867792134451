import React from 'react'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { push } from 'connected-react-router'

import { logout } from 'redux/user/actions'
import { checkPermission } from 'redux/user/selectors'
import { formatDate } from 'utils/formatting'
import { Upgrade } from 'components/UI/Button'

import styles from './style.module.scss'

@connect(
  (state) => {
    return {
      user: state.user,
      billing: state.billing,
      hasAccessToBilling: checkPermission(state, 'billing'),
    }
  },
  (dispatch) => {
    return {
      onLogout: () => dispatch(logout()),
      push: (path) => dispatch(push(path)),
    }
  },
)
class ProfileMenu extends React.Component {
  logout = async (e) => {
    e.preventDefault()
    await this.props.onLogout()
    this.props.push('/user/login')
  }

  goToUpgradeHandler = () => {
    if (this.props.billing.can_be_renewed) {
      this.props.push('/settings/billing/upgrade?isRenew=1')
      return
    }
    this.props.push('/settings/billing/upgrade')
  }

  render() {
    const { user, billing, intl, hasAccessToBilling } = this.props
    const items = [
      {
        key: 'topBar.profileMenu.hello',
        label: (
          <Link to="/settings/profile">
            <strong>
              <FormattedMessage id="topBar.profileMenu.hello" />, {user.name || 'Anonymous'}
            </strong>
          </Link>
        ),
      },
      {
        key: 'divider-1',
        type: 'divider',
      },
      {
        key: 'topBar.billing.plan',
        label: (
          <>
            <strong>
              <FormattedMessage id="topBar.billing.plan" />:{' '}
            </strong>
            {billing.plan}
            <br />
            <strong>
              <FormattedMessage id="topBar.billing.active_until" />:{' '}
            </strong>
            {billing.expiry_date ? (
              formatDate(new Date(billing.expiry_date).setDate(new Date(billing.expiry_date).getDate() - 1))
            ) : (
              <FormattedMessage id="topBar.billing.forever" />
            )}
            <br />
            {(billing.can_be_upgraded || billing.can_be_renewed) && hasAccessToBilling && (
              <Upgrade
                onClick={this.goToUpgradeHandler}
                title={intl.formatMessage({
                  id: billing.can_be_renewed ? 'button.renew' : 'button.upgrade',
                })}
              />
            )}
          </>
        ),
      },
      {
        key: 'divider-2',
        type: 'divider',
      },
      {
        key: 'topBar.profileMenu.email',
        label: (
          <Link to="/settings/profile">
            <div>
              <strong>
                <FormattedMessage id="topBar.profileMenu.email" />:{' '}
              </strong>
              {user.email}
              <br />
              <strong>
                <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
              </strong>
              {user.phone_number || '-'}
            </div>
          </Link>
        ),
      },
      {
        key: 'divider-3',
        type: 'divider',
      },
      {
        key: 'referral',
        label: (
          <Link to="/referral">
            <FormattedMessage id="referral.referral" />
          </Link>
        ),
        icon: <FontAwesomeIcon icon="link" className={`${styles.menuIcon}`} />,
      },
      {
        key: 'divider-4',
        type: 'divider',
      },
      {
        key: 'profile.profile',
        label: (
          <Link to="/settings/profile">
            <FormattedMessage id="profile.profile" />
          </Link>
        ),
        icon: <FontAwesomeIcon icon="user" className={`${styles.menuIcon}`} />,
      },
      {
        key: 'divider-5',
        type: 'divider',
      },
      {
        key: 'topBar.profileMenu.logout',
        label: (
          <a href="#" onClick={this.logout}>
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        ),
        icon: <FontAwesomeIcon icon="sign-out-alt" className={`${styles.menuIcon}`} />,
      },
    ]
    const menu = <Menu selectable={false} items={items} />
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Avatar
            className={styles.avatar}
            shape="square"
            size="large"
            icon={<UserOutlined />}
            src={user.profile_image}
          />
        </div>
      </Dropdown>
    )
  }
}

export default injectIntl(ProfileMenu)
