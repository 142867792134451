import React, { Component } from 'react'
import Pusher from 'pusher-js'
import { getToken } from 'utils/cookies'
import { generateBaseUri } from 'utils/helper'

export const authPusher = () => {
  // if (process.env.NODE_ENV === 'development') {
  //   Pusher.logToConsole = true;
  // }
  // Log dihidupkan untuk memudahkan debugging
  Pusher.logToConsole = true

  const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    forceTLS: true,
    authEndpoint: `${generateBaseUri()}/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  })

  return pusher
}

const witPusherProgress = (WrappedComponent) => {
  // eslint-disable-next-line
  const HOC = class extends Component {
    constructor(props) {
      super(props)

      this.pusher = null
      this.channel = null
      this.timer = null
      this.state = {
        dataImported: {
          total: 0,
          valid: 0,
          invalid: 0,
        },
        dataPusher: {},
        channel: '',
        eventName: 'App\\Events\\ImportProgress',
        statusImported: false,
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevState.channel !== this.state.channel) {
        if (this.state.channel) {
          this.pusher = authPusher()
          this.unsubscribeCurrentChannel()
          this.channel = this.pusher.subscribe(this.state.channel)
          this.channel.bind(this.state.eventName, (data) => {
            const { progress } = data
            if (progress) {
              this.setState({
                dataImported: {
                  total: progress.total,
                  valid: progress.valid,
                  invalid: progress.invalid,
                },
                dataPusher: progress,
                statusImported: progress.valid + progress.invalid >= progress.total,
              })
            }
          })
          // Dalam 30 menit koneksi pusher akan ditutup
          this.timer = setTimeout(() => this.disconnect(), 1800000)
        }
      }
    }

    componentWillUnmount() {
      this.disconnect()
      clearTimeout(this.timer)
    }

    setChannel = (channel, eventName = 'App\\Events\\ImportProgress') => {
      this.setState({ channel, eventName })
    }

    resetCurrentData = () => {
      this.setState({
        dataImported: {
          total: 0,
          valid: 0,
          invalid: 0,
        },
        dataPusher: {},
        statusImported: false,
      })
    }

    unsubscribeCurrentChannel = () => {
      if (this.state.channel) {
        this.pusher.unsubscribe(this.state.channel)
        this.setState({
          dataImported: {
            total: 0,
            valid: 0,
            invalid: 0,
          },
          dataPusher: {},
          channel: '',
          statusImported: false,
        })
      }
    }

    disconnect = () => {
      if (this.pusher) {
        if (this.channel) {
          this.channel.unbind()
        }
        this.pusher.disconnect()
      }
    }

    render() {
      const {
        dataImported: { invalid, valid, total },
        statusImported,
        channel,
      } = this.state

      const imported = invalid + valid
      const progress = {
        imported,
        total,
        progress: total > 0 && imported > 0 ? Math.floor((imported / total) * 100) : 0,
      }

      return (
        <WrappedComponent
          onSetChannel={this.setChannel}
          progress={progress}
          dataImported={this.state.dataImported}
          dataPusher={this.state.dataPusher}
          statusImported={statusImported}
          channelName={channel}
          unsubscribeCurrentChannel={this.unsubscribeCurrentChannel}
          disconnect={this.disconnect}
          resetCurrentData={this.resetCurrentData}
          {...this.props}
        />
      )
    }
  }

  return HOC
}

export default witPusherProgress
