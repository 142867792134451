import React from 'react'

import { Helmet } from 'components/UI'
import { Typography } from 'antd'
import styles from './style.module.scss'

const privacyPolicy = [
  'Akun yang sudah ditutup tidak dapat dibuka kembali.',
  'Data email/nomor handphone pada akun yang sudah dinonaktifkan tidak dapat digunakan kembali atau didaftarkan pada akun lainnya.',
  'Penutupan akun hanya dapat diproses apabila sudah tidak ada data keuangan di akun kamu.',
  'Pastikan kamu telah menghapus semua data keuangan, akuntansi dan transaksi pada akun Kledo mu.',
]

const stepForCloseAccount = [
  'Pastikan akun yang ingin ditutup sudah sesuai seperti informasi Syarat & Ketentuan di atas.',
  'Kirim ke WA 6282383334000 , sebutkan alamat email akunmu dan alasan mengapa ingin hapus akun.',
  'Klik konfirmasi penghapusan akun pada emailmu.',
  'Kamu sudah berhasil tutup akun.',
]

const RequestDeleteAccount = () => {
  return (
    <div className='request-delete-account'>
      <Helmet title="Invite" />
      <div className={`${styles.title}`}>
        <h1>
          <strong>Hapus Akun Kledo</strong>
        </h1>
      </div>
      <div className={styles.block}>
        <div className="row">
          <div className="col-xl-12">
            <div className={styles.inner}>
              <Typography>
                <Typography.Paragraph>
                  <Typography.Text strong>
                    Apabila kamu memiliki akun Kledo dan ingin menutup akun tersebut, silakan ikuti
                    panduan dan tips berikut ini:
                  </Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  <ul>
                    <Typography.Title level={3}>Syarat & Ketentuan</Typography.Title>
                    {privacyPolicy.map((row, index) => {
                      return (
                        <li key={`${index + 1}`}>
                          <Typography.Text>{row}</Typography.Text>
                        </li>
                      )
                    })}
                  </ul>
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <ul>
                    <Typography.Title level={3}>Cara Tutup Akun</Typography.Title>
                    {stepForCloseAccount.map((row, index) => {
                      return (
                        <li key={`${index + 1}`}>
                          <Typography.Text>{row}</Typography.Text>
                        </li>
                      )
                    })}
                  </ul>
                </Typography.Paragraph>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestDeleteAccount
