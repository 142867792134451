import React, { useEffect } from 'react'
import { Dropdown, Menu } from 'antd'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { useQueryClient } from 'react-query'

import { useResponsive } from 'utils/hooks'
import styles from './style.module.scss'

const CompanySelector = ({ onChangeCompany, companies, intl, pushPath }) => {
  const { isMobileView } = useResponsive()

  const queryClient = useQueryClient()

  useEffect(() => {
    if (isMobileView) {
      document.querySelector('header.ant-layout-header').classList.add(styles.lineHeightInherit)
    } else {
      document.querySelector('header.ant-layout-header').classList.remove(styles.lineHeightInherit)
    }
  }, [isMobileView])

  const changeCompany = ({ key }) => {
    if (key === 'list') {
      pushPath('/companies')
    } else if (key === 'add') {
      pushPath('/companies/add')
    } else if (companies.selectedEndpoint !== key) {
      onChangeCompany(key, () => {
        // Clear all cache from react-query to prevent sharing cache data between company.
        // Sharing cache data will cause current company use last cache data from previous company
        // until current fetching success
        queryClient.clear()
      })
    }
  }

  const selectedCompany = companies.companies.filter(
    (e) => e.endpoint === companies.selectedEndpoint,
  )
  const selected = selectedCompany[0] ? selectedCompany[0].name : ''
  const items = companies.companies.map((item) => {
    return {
      key: item.endpoint,
      label: item.name,
    }
  })
  items.push({
    type: 'divider',
    key: Math.random(),
  })
  if (companies.companies.length > 1) {
    items.push({
      key: 'list',
      label: intl.formatMessage({ id: 'companies.company_list' }),
    })
  } else {
    items.push({
      key: 'add',
      label: intl.formatMessage({ id: 'companies.add_company' }),
    })
  }

  const langMenu = (
    <Menu
      className={styles.menu}
      selectedKeys={[companies.selectedEndpoint]}
      onClick={changeCompany}
      items={items}
    />
  )
  return (
    <Dropdown overlay={langMenu} trigger={['click']}>
      <div className={styles.dropdown}>
        <strong className="text-uppercase" style={{ lineHeight: 'inherit' }}>
          {selected}
        </strong>
      </div>
    </Dropdown>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    pushPath: (path) => dispatch(push(path)),
  }
}

export default connect(() => ({}), mapDispatchToProps)(injectIntl(CompanySelector))
