import { message } from 'antd'
import { useMutation, useQuery } from 'react-query'

import {
  getFinancePurchaseOrder,
  getFinancePurchaseOrders,
  getRecurringPurchaseOrders,
  purchaseOrderPaymentFinanceBankTrans,
} from 'utils/apis'
import { useDispatch, useSelector } from 'react-redux'
import { optionsSelector } from 'redux/selectors'
import { editOption } from 'redux/options/actions'

export const usePurchaseOrders = ({ payload = {}, enabled = false }) => {
  const options = useSelector(optionsSelector)
  const dispatch = useDispatch()

  // prevent user that already setting per_page > 100
  if (payload.per_page > 100) {
    payload.per_page = 100
  }

  if (payload.per_page && payload.per_page !== options.purchases_per_page_default) {
    dispatch(
      editOption({
        purchases_per_page_default: payload.per_page,
      }),
    )
  }

  return useQuery(
    ['purchaseOrder', payload],
    async () => {
      const {
        data: { data },
      } = await getFinancePurchaseOrders(payload)
      return data
    },
    {
      enabled,
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
    },
  )
}

export const useRecurringPurchaseOrders = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['purchaseOrder', 'recurring', payload],
    async () => {
      const {
        data: { data },
      } = await getRecurringPurchaseOrders(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}

export function usePurchaseOrder({ id, payload = {}, enabled = false }) {
  return useQuery(
    ['purchaseOrder', id, payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinancePurchaseOrder(id, payload)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}

export function useMutationPurchaseOrderPaymentFinanceBankTrans({ options } = {}) {
  return useMutation((payload) => purchaseOrderPaymentFinanceBankTrans(payload), options)
}
