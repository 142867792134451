import React from 'react'
import { Dropdown, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { injectIntl } from 'react-intl'

import News from 'containers/Notification/News'
import { useResponsive } from 'utils/hooks'
import styles from './style.module.scss'

const Actions = ({ intl }) => {
  const [visible, setVisible] = React.useState(false)

  const { isMobileView } = useResponsive()

  const visibleChangeHandler = (flag) => setVisible(flag)

  const stylePane = { height: 400, overflow: 'auto' }
  if (!isMobileView) {
    stylePane.width = 400
  }

  const menu = (
    <div className="card cui__utils__shadow width-350 overflow-hidden border-0">
      <div className="card-body" style={stylePane}>
        <News />
      </div>
    </div>
  )
  return (
    <Tooltip
      visible={visible ? false : undefined}
      title={intl.formatMessage({ id: 'topBar.latestKledoUpdate' })}
    >
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomLeft"
        visible={visible}
        onVisibleChange={visibleChangeHandler}
      >
        <div className={styles.dropdown}>
          <FontAwesomeIcon icon="bell" className={`${styles.icon}`} />
        </div>
      </Dropdown>
    </Tooltip>
  )
}

export default injectIntl(Actions)
