import React, { forwardRef, useMemo } from 'react'
import { Form, Select } from 'antd'
import { injectIntl } from 'react-intl'
import moment from 'moment'

import { RangePicker } from 'components/UI'
import TagSuggestion from 'containers/FinanceTag/TagSuggestion'

const FilterFormTransaction = ({
  intl,
  innerRef,
  params,
  withTag,
  withTransType,
  onClearTag,
  onDeselectTag,
  onSelectTag,
  selectedTags,
  transType = [],
}) => {
  const [form] = Form.useForm()
  const initialValues = useMemo(() => {
    const values = {
      date: params.date_from ? [moment(params.date_from), moment(params.date_to)] : undefined,
    }

    if (withTag) values.tags = params.tags || []
    if (withTransType) values.trans_type_ids = params.trans_type_ids || []

    return values
  }, [params.date_from, params.date_to, params.tags, params.trans_type_ids, withTag, withTransType])

  return (
    <Form
      // onValuesChange={onValuesChange}
      form={form}
      ref={innerRef}
      name="FilterForm"
      initialValues={initialValues}
      layout="vertical"
    >
      <Form.Item label={intl.formatMessage({ id: 'financeProduct.date' })} name="date">
        <RangePicker />
      </Form.Item>
      {withTag && (
        <TagSuggestion
          form={form}
          name="tags"
          withExtra={false}
          defaultTag={selectedTags || []}
          onClear={onClearTag}
          onDeselect={onDeselectTag}
          onSelect={onSelectTag}
        />
      )}
      {withTransType ? (
        <Form.Item
          label={intl.formatMessage({ id: 'report.transactions_type' })}
          name="trans_type_ids"
        >
          <Select
            mode="multiple"
            size="middle"
            placeholder={intl.formatMessage({ id: 'report.choose_transactions_type' })}
            style={{ width: '100%' }}
            allowClear
            showSearch
            options={transType}
            filterOption={(inputValue, option) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase())
            }
          />
        </Form.Item>
      ) : null}
    </Form>
  )
}

const Component = injectIntl(FilterFormTransaction)

export default forwardRef((props, ref) => <Component innerRef={ref} {...props} />)
