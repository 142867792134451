import { useQuery } from 'react-query'
import { message } from 'antd'

import { getRoles } from 'utils/apis'

export function useRoles({ enabled = false, options }) {
  return useQuery(
    ['roles'],
    async () => {
      let response
      try {
        response = (await getRoles()).data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}
