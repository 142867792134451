/* eslint-disable */
import React, { useMemo, useState, useRef } from "react";
import { Button, Col, Popover, Progress, Row, Space, Spin, Typography } from "antd";
import { injectIntl } from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import { mobileViewSelector } from 'redux/selectors';
import { CheckOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { useOnboardingStatus } from "utils/queries";
import { Link } from "react-router-dom";
import { Delete } from "components/UI";
import {editOption} from "redux/options/actions";
import {useQueryClient} from "react-query";
import styles from "./style.module.scss";

const Onboarding = ({ intl }) => {
  const [open, setOpen] = useState(false);
  const isMobileView = useSelector(mobileViewSelector);
  const popoverRef = useRef(null);
  const [isLoadingOnboarding, setIsLoadingOnboarding] = useState(false)
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const { data, isLoading } = useOnboardingStatus();

  const visibleChangeHandler = (newOpen) => {
    if (newOpen) {
      setIsLoadingOnboarding(true); // Set isLoadingOnboarding to true when the popover is opened
      queryClient.invalidateQueries(['onboarding-status'])
        .then(() => setIsLoadingOnboarding(false)) // Set isLoadingOnboarding to false when the query is completed
        .catch(() => setIsLoadingOnboarding(false)); // Handle errors and set isLoadingOnboarding to false
    }
    setOpen(newOpen);
  };

  const formattedData = useMemo(() => {
    function isChecklistDone(checklistId) {
      const progress = data?.progress_detail.find(item => item.checklist_id === checklistId);
      return progress ? progress?.completed_at !== null : false;
    }

    const newData = [];

    if (data && data?.checklist && Array.isArray(data?.checklist)) {
      (data?.checklist ?? []).forEach((item) => {
        newData.push({
          id: item.id,
          app_id: item.app_id,
          title: item.title,
          desc: item.desc,
          button_text: item.button_text,
          button_dest: item.button_dest,
          position: item.position,
          isDone: isChecklistDone(item.id),
        });
      });
    }

    return newData;
  }, [data]);

  const handleNavigationButton = () => {
    if (popoverRef.current) {
      popoverRef.current.setPopupVisible(false);
    }
  };

  const handleDismissButton = () => {
    dispatch(
      editOption({
        dismiss_onboarding_checklist: 1,
      }),
    )
    if (popoverRef.current) {
      popoverRef.current.setPopupVisible(false);
    }
  };

  const renderSubContent = (item) => {
    return (
      <div className={styles.onboardingSubItem}>
        <Space align="start">
          <ExclamationCircleFilled style={{ color: "#4777F3", fontSize: 24 }} />
          <Row>
            <Col span={24}>
              <Space direction="vertical" size={4}>
                <Typography.Text strong style={{ color: "#4777F3", fontSize: 16 }}>{item.title}</Typography.Text>
                <Typography.Text style={{ fontSize: 14 }}>{item.desc}</Typography.Text>
              </Space>
            </Col>
            <Col span={24} align="right">
              <Link to={item.button_dest}>
                <Button onClick={() => handleNavigationButton(item.id)} disabled={item.isDone} style={{ marginTop: 4, borderRadius: 4 }} type="primary" title={item.button_text}>
                  {item.button_text}
                </Button>
              </Link>
            </Col>
          </Row>
        </Space>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <Spin spinning={isLoadingOnboarding || isLoading}>
          <div className={styles.onboarding}>
            <Typography.Text strong className={styles.onboardingTitle}>{intl.formatMessage({ id: 'onBoarding.checklist_greeting' })}</Typography.Text>
            <div style={{ display: "flex", alignItems: "center", marginTop: 12, }}>
              <Typography.Text strong className={styles.progressBarText}>{data?.progress?.completed_percentage}%</Typography.Text>
              <Progress percent={data?.progress?.completed_percentage} showInfo={false} strokeColor="#FADB14" className={styles.progressBar} />
            </div>
            {formattedData?.map((item) => {
              return (
                <Popover key={item.id} placement="leftBottom" content={renderSubContent(item)} overlayClassName={styles.popoverNoPadding} overlayInnerStyle={{ borderRadius: 8 }}>
                  <div className={styles.onboardingItem}>
                    <div className={styles.onboardingChecklist} style={{ background: item.isDone ? "#4777F3" : "white", border: item.isDone && "none" }}>
                      <CheckOutlined style={{ color: "white" }} />
                    </div>
                    <Typography.Text
                      strong
                      style={{
                        color: item.isDone ? "#8C8C8C" : "black",
                        fontSize: 14,
                        marginRight: 8,
                      }}
                    >
                      {item.title}
                    </Typography.Text>
                  </div>
                </Popover>
              );
            })}
            {
              (!isLoading && data?.progress?.completed_percentage === 100) &&
              <Delete className={styles.delete} title="Dismiss Checklist" onClick={() => handleDismissButton()} />
            }
          </div>
        </Spin>
      </>
    );
  };

  return (
    <Popover
      content={renderContent()}
      style={{ alignItems: "center", padding: 0 }}
      placement="topRight"
      trigger="click"
      overlayStyle={{
        position: "fixed",
      }}
      overlayInnerStyle={{ borderRadius: 8 }}
      overlayClassName={styles.popoverNoPadding}
      showArrow={false}
      onVisibleChange={(visible) => {
        visibleChangeHandler(visible);
        if (!visible) {
          setOpen(false);
        }
      }}
      ref={popoverRef}
    >
      <Button
        rel="noopener noreferrer"
        type="primary"
        shape="circle"
        icon={
          <img
            src={
              open
                ? "resources/images/icons/arrow_down_fab.svg"
                : "resources/images/icons/kledo.svg"
            }
            style={{
              transform: open ? "rotate(-360deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
            alt={open ? "arrow_down" : "kledo"}
          />
        }
        style={{
          right: "1rem",
          bottom: "4rem",
          position: "fixed",
          backgroundColor: "#4777F3",
          borderColor: "#6C92F5",
          zIndex: 15,
          height: isMobileView ? '' : 48,
          width: isMobileView ? '' : 48,
        }}
      />
    </Popover>
  );
};

export default injectIntl(Onboarding);
