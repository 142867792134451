import { message } from 'antd'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import {
  getFinanceOrder,
  getFinanceOrders,
  getRecurringOrders,
  orderPaymentFinanceBankTrans,
} from 'utils/apis'
import { optionsSelector } from 'redux/selectors'
import { editOption } from 'redux/options/actions'

export const useOrders = ({
  payload = {},
  enabled = false,
  saveDataPagination = true,
  options = {},
}) => {
  const optionsState = useSelector(optionsSelector)
  const dispatch = useDispatch()

  if (saveDataPagination) {
    // prevent user that already setting per_page > 100
    if (payload.per_page > 100) {
      payload.per_page = 100
    }

    if (payload.per_page && payload.per_page !== optionsState.sales_per_page_default) {
      dispatch(
        editOption({
          sales_per_page_default: payload.per_page,
        }),
      )
    }
  }

  return useQuery(
    ['order', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceOrders(payload)
      return data
    },
    {
      enabled,
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useRecurringOrders = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['order', 'recurring', payload],
    async () => {
      const {
        data: { data },
      } = await getRecurringOrders(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export function useOrder({ id, payload = {}, enabled = false }) {
  return useQuery(
    ['order', id, payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceOrder(id, payload)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export function useMutationOrderPaymentFinanceBankTrans({ options } = {}) {
  return useMutation((payload) => orderPaymentFinanceBankTrans(payload), options)
}

// export function useOrder({ id, payload = {}, enabled = false }) {
//   return useQuery(
//     ['order', id, payload],
//     async () => {
//       let response
//       try {
//         const {
//           data: { data },
//         } = await getFinanceOrder(id, payload)
//         response = data
//       } catch (error) {
//         throw new Error('Failed to load data from server!')
//       }
//       return response
//     },
//     {
//       enabled: !!id && enabled,
//       onError: (error) =>
//         message.error((error && error.message) || 'Failed to load data from server!'),
//     },
//   )
// }
