import React from 'react'
import { Dropdown, Menu, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { changeLanguage } from 'redux/settings/actions'
import styles from './style.module.scss'

@connect(
  ({ settings, refs: { lang } }) => ({ settings, lang }),
  (dispatch) => {
    return {
      onChangeLanguage: (payload) => dispatch(changeLanguage(payload)),
    }
  },
)
class LanguageSelector extends React.PureComponent {
  state = {
    visibleDropdown: false,
  }

  changeLang = ({ key }) => {
    const {
      onChangeLanguage,
      settings: { locale },
    } = this.props
    if (locale !== key) {
      onChangeLanguage({
        setting: 'locale',
        value: key,
      })
    }
  }

  render() {
    const {
      settings: { locale },
      intl,
    } = this.props
    const language = locale.substr(0, 2)
    const items = this.props.lang.map((item) => {
      return {
        key: item.value,
        label: (
          <>
            <span role="img" aria-label={item.name} className="mr-2 text-uppercase">
              {item.value.substr(0, 2)}
            </span>
            {item.name}
          </>
        ),
      }
    })

    const langMenu = (
      <Menu
        className={styles.menu}
        selectedKeys={[locale]}
        onClick={this.changeLang}
        items={items}
      />
    )
    return (
      <Tooltip
        visible={this.state.visibleDropdown ? false : undefined}
        title={intl.formatMessage({ id: 'topBar.changeLanguage' })}
      >
        <Dropdown
          visible={this.state.visibleDropdown}
          onVisibleChange={(visible) => this.setState({ visibleDropdown: visible })}
          overlay={langMenu}
          trigger={['click']}
        >
          <div className={styles.dropdown}>
            <strong className="text-uppercase">{language}</strong>
          </div>
        </Dropdown>
      </Tooltip>
    )
  }
}

export default injectIntl(LanguageSelector)
