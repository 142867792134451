import { message } from 'antd'
import { useQuery } from 'react-query'

import { getRecurringManualJournals } from 'utils/apis'

export const useRecurringManualJournals = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['manualJournal', 'recurring', payload],
    async () => {
      const {
        data: { data },
      } = await getRecurringManualJournals(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}
