import React from 'react'
import { Col, Row, Tag } from 'antd'
import PropTypes from 'prop-types'
import { transType } from 'utils/static'
import LinkTransType from '../LinkTransType'

const Tags = ({ asLink, data, tagProps }) => {
  return (
    <Row>
      {data?.map?.((tag) => {
        if (!tag) return null
        return (
          <Col key={tag.id} className="mt-2">
            <Tag {...tagProps}>
              {asLink ? (
                <LinkTransType title={tag.name} transTypeId={transType.TAG} data={{ id: tag.id }} />
              ) : (
                tag.name
              )}
            </Tag>
          </Col>
        )
      })}
    </Row>
  )
}

Tags.propTypes = {
  data: PropTypes.array.isRequired,
  goTo: PropTypes.func,
  tagProps: PropTypes.object,
  asLink: PropTypes.bool,
}

Tags.defaultProps = {
  goTo: () => {},
  tagProps: {},
  asLink: true,
}

export default Tags
