import React, { useMemo } from 'react'
import { Dropdown, Menu } from 'antd'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { DownOutlined, LoadingOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons'
import Save from './Save'

function SaveAddNew({ id, loading, onClick, onClickNew, onCancel, ...props }) {
  const itemActions = useMemo(() => {
    const items = [
      {
        key: 2,
        icon: <SaveOutlined />,
        onClick: () => onClickNew(),
        label: props.intl.formatMessage({ id: 'button.save_add_new' }),
      },
    ]
    if (typeof onCancel === 'function') {
      items.push({
        key: 3,
        icon: <CloseOutlined />,
        onClick: onCancel,
        label: props.intl.formatMessage({ id: 'button.cancel' }),
      })
    }
    return items
  }, [onCancel, onClickNew, props.intl])

  const saveMenu = <Menu items={itemActions} />

  return (
    <Dropdown.Button
      {...props}
      style={{ width: '100%' }}
      overlay={saveMenu}
      icon={loading ? <LoadingOutlined /> : <DownOutlined />}
      onClick={() => onClick()}
      disabled={loading}
      buttonsRender={(btns) => {
        return [<Save id={id} {...btns[0].props} style={{ flex: 1 }} />, btns[1]]
      }}
    />
  )
}

SaveAddNew.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  onClickNew: PropTypes.func,
}

SaveAddNew.defaultProps = {
  type: 'primary',
  id: 'btn-save',
  onClick: () => {},
  onClickNew: () => {},
}

export default injectIntl(SaveAddNew)
