import React, { useCallback, useRef, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Input, Button, Checkbox, Form, Divider, Space, message } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useGoogleLogin } from '@react-oauth/google'
import { getSavedEmail } from 'utils/cookies'

const LoginForm = ({ intl, onSubmit, onGoogleLogin, loading, email }) => {
  const [form] = Form.useForm()
  const [editEmail, setEditEmail] = useState(false)

  const failureGoogleLoginHandler = useCallback(
    (e) => {
      message.error(e?.message || intl.formatMessage({ id: 'notification.login_google_failure' }))
    },
    [intl],
  )
  const login = useGoogleLogin({
    onSuccess: onGoogleLogin,
    onFailure: onGoogleLogin,
    cookiePolicy: 'single_host_origin',
    onError: failureGoogleLoginHandler,
    flow: 'auth-code',
  })


  const savedEmail = email || getSavedEmail()
  const emailRef = useRef()
  const passwordRef = useRef()

  const onEmailFocus = useCallback(() => {
    setEditEmail(true)
    setTimeout(() => {
      if (emailRef.current) {
        emailRef.current.focus()
        emailRef.current.input.setSelectionRange(0, form.getFieldValue('email').length)
      }
    }, 1)
  }, [form])

  const onEmailBlur = useCallback(() => {
    form.setFieldsValue({
      email: form.getFieldValue('email') ? form.getFieldValue('email').trim() : '',
    })
    if (form.getFieldsError(['email'])?.[0]?.errors?.length < 1 && passwordRef.current?.focus) {
      passwordRef.current.focus()
      setEditEmail(false)
    }
  }, [form])

  const googleLoginHandler = useCallback(() => login(), [login])

  return (
    <Form
      form={form}
      layout="vertical"
      hideRequiredMark
      onFinish={onSubmit}
      initialValues={{
        email: savedEmail ? savedEmail.trim() : '',
        remember_me: true,
      }}
    >
      <Form.Item
        label={intl.formatMessage({ id: 'users.email' })}
        onClick={editEmail ? undefined : onEmailFocus}
      >
        {savedEmail && !editEmail ? (
          <>
            {form.getFieldValue('email') || savedEmail}
            <Button icon={<EditOutlined />} shape="circle" type="text" onClick={onEmailFocus} />
          </>
        ) : null}
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'users.please_input_your_email_address' }),
            },
            {
              type: 'email',
              message: intl.formatMessage({ id: 'users.the_input_is_not_valid_email_' }),
            },
          ]}
          noStyle
        >
          <Input
            ref={emailRef}
            size="default"
            type={savedEmail && !editEmail ? 'hidden' : undefined}
            autoFocus
            onBlur={onEmailBlur}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'users.password' })}
        name="password"
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'users.please_input_your_password' }),
          },
        ]}
      >
        <Input.Password ref={passwordRef} autoFocus={savedEmail} />
      </Form.Item>
      <Form.Item>
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <Form.Item name="remember_me" valuePropName="checked" noStyle>
            <Checkbox>{intl.formatMessage({ id: 'users.remember_me' })}</Checkbox>
          </Form.Item>
          <Link to="/user/forgot" className="utils__link--blue utils__link--underlined pull-right">
            {intl.formatMessage({ id: 'users.forgot_password_' })}
          </Link>
        </Space>
      </Form.Item>
      <Button block type="primary" className="mt-2" htmlType="submit" loading={loading}>
        {intl.formatMessage({ id: 'users.login' })}
      </Button>
      <Divider className="my-4" />
      <Button
        onClick={googleLoginHandler}
        type="text"
        className="py-2 border"
        style={{ height: 'auto' }}
        disabled={loading}
        block
      >
        <img
          src="./resources/images/icons/google.svg"
          alt="Google Icon"
          width={18}
          className="mr-3"
        />
        {intl.formatMessage({ id: 'users.login_with_google' })}
      </Button>
    </Form>
  )
}

export default injectIntl(LoginForm)
